<template>
  <v-container id="organizations" fluid tag="section">
    <loader :active="loaderActive" />
    <v-row>
      <v-col cols="12" sm="12" lg="12">
        <base-material-stats-card
          color="primary"
          icon="mdi-check"
          title="Total"
          :value="
            responsePayload.total ? responsePayload.total.toString() : '0'
          "
        />
      </v-col>
      <v-col cols="12" md="12">
        <v-row rows="12" align="end" justify="end">
          <div class="my-2">
            <template>
              <v-dialog
                v-model="dialog"
                max-width="800px"
                transition="dialog-top-transition"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    elevation="9"
                    color="primary"
                    x-large
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon left>mdi-office-building-plus</v-icon>
                    Create Organization
                  </v-btn>
                </template>
                <v-card>
                  <v-toolbar dark color="primary">
                    <v-toolbar-title>{{ formTitle }}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                      <v-btn icon dark @click="dialog = false">
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                    </v-toolbar-items>
                  </v-toolbar>
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12" sm="6">
                          <v-text-field
                            v-model="editedItem.name"
                            :error-messages="companyNameErrors"
                            @input="$v.editedItem.name.$touch()"
                            @blur="$v.editedItem.name.$touch()"
                            label="Company Name*"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <v-text-field
                            v-model.trim="editedItem.domain"
                            :error-messages="companyDomainErrors"
                            @input="$v.editedItem.domain.$touch()"
                            @blur="$v.editedItem.domain.$touch()"
                            label="Company Domain*"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <v-text-field
                            v-model.number="editedItem.primaryPhone"
                            :error-messages="primaryPhonenumberErrors"
                            @input="$v.editedItem.primaryPhone.$touch()"
                            @blur="$v.editedItem.primaryPhone.$touch()"
                            label="Primary Phonenumber*"
                            type="number"
                            inputmode="”numeric”"
                            required
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <v-text-field
                            v-model.number="editedItem.secondaryPhone"
                            label="Secondary Phonenumber"
                            type="number"
                            inputmode="”numeric”"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <v-text-field
                            v-model.trim="editedItem.primaryEmail"
                            :error-messages="primaryEmailErrors"
                            @input="$v.editedItem.primaryEmail.$touch()"
                            @blur="$v.editedItem.primaryEmail.$touch()"
                            label="Primary Email*"
                            type="email"
                            required
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <v-text-field
                            v-model.trim="editedItem.secondaryEmail"
                            label="Secondary Email"
                            type="email"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <v-text-field
                            v-model.number="editedItem.payrollDate"
                            :error-messages="payrollDateErrors"
                            @input="$v.editedItem.payrollDate.$touch()"
                            @blur="$v.editedItem.payrollDate.$touch()"
                            label="Payroll Date*"
                            type="number"
                            required
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <v-text-field
                            v-model.number="editedItem.interestPercentage"
                            label="Interest Percentage(%)"
                            type="number"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <v-select
                            :items="['Active', 'Suspended']"
                            label="Status*"
                            required
                          ></v-select>
                        </v-col>
                        <v-col cols="12">
                          <v-text-field
                            v-model="editedItem.address"
                            :error-messages="addressErrors"
                            @input="$v.editedItem.address.$touch()"
                            @blur="$v.editedItem.address.$touch()"
                            label="Company Address*"
                            required
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="close"> Cancel </v-btn>
                    <v-btn color="primary" text @click="save"> Save </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card>
                  <v-card-title class="text-h5"
                    >Are you sure you want to delete
                    {{ editedItem.name }}?</v-card-title
                  >
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="closeDelete"
                      >Cancel</v-btn
                    >
                    <v-btn color="primary" text @click="deleteItemConfirm"
                      >OK</v-btn
                    >
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </template>
          </div>
        </v-row>
      </v-col>
      <v-col cols="12" md="12">
        <base-material-card color="primary" class="px-5 py-3">
          <template v-slot:heading>
            <div class="display-2 font-weight-dark lightgray--text">
              Organization List
              <button class="float-right">Download</button>
              <v-spacer />
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
                color="lightgray"
              />
            </div>
            <div class="subtitle-1 font-weight-light"></div>
          </template>
          <v-card-text>
            <v-data-table
              :headers="headers"
              :items="items"
              :search="search"
              :loading="loaderActive"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              class="elevation-1"
              ><template v-slot:[`item.actions`]="{ item }">
                <v-icon
                  color="green"
                  medium
                  class="mr-2"
                  @click="editItem(item)"
                >
                  mdi-pencil
                </v-icon>
                <v-icon color="red" medium @click="deleteItem(item)">
                  mdi-delete
                </v-icon>
              </template>
              <template v-slot:no-data>
                <v-btn color="primary" @click="initialize"> Reset </v-btn>
              </template>
              <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length">
                  <v-spacer />
                  More info about {{ item.id }}
                  <v-spacer />
                  More info about {{ item.username }}
                  <v-spacer>
                    More info about {{ item.email }}
                    <v-spacer />
                    More info about {{ item.status }}
                    <v-spacer />
                    Created at {{ item.createdAt }}
                  </v-spacer>
                  <v-spacer />
                </td>
              </template>
            </v-data-table>
          </v-card-text>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { validationMixin } from "vuelidate";
import {
  required,
  maxLength,
  minLength,
  email,
  between,
  integer,
  numeric,
} from "vuelidate/lib/validators";
import { mapState } from "vuex";
import store from "../../../../store";
import loaderMixin from "../../../../mixins/loader";
export default {
  components: {
    Loader: () => import("../../../../components/Loader"),
  },
  mixins: [validationMixin, loaderMixin],
  validations: {
    editedItem: {
      name:{required},
      domain:{required},
      primaryEmail: { required, email },
      primaryPhone: {
        required,numeric,
        maxLength: maxLength(13),
        minLength: minLength(10),
      },
      payrollDate: { required, betweenValue: between(1, 31), integer },
      statusID:{required,integer},
      address:{required}
    },
  },
  data: () => ({
    responsePayload: {},
    dialog: false,
    search: "",
    dialogDelete: false,
    sortBy: "id",
    sortDesc: true,
    expanded: [],
    singleExpand: false,
    headers: [
      {
        sortable: true,
        text: "Created At",
        value: "createdAt",
        align: "left",
      },
      {
        sortable: false,
        text: "Name",
        value: "name",
      },
      {
        sortable: true,
        text: "Interest Rate(%)",
        value: "interestPercentage",
        align: "center",
      },
      {
        sortable: true,
        text: "Primary Email",
        value: "primaryEmail",
      },
      {
        sortable: true,
        text: "Primary Phone",
        value: "primaryPhone",
      },
      {
        sortable: false,
        text: "Payroll Date",
        value: "payrollDate",
        align: "center",
      },
      {
        sortable: true,
        text: "Status",
        value: "status.name",
      },
      { text: "Actions", value: "actions", sortable: false, align: "right" },
    ],
    items: [],
    editedIndex: -1,
    editedItem: {
      payrollDate: "",
      name: "",
      interestPercentage: "",
      primaryEmail: "",
      secondaryEmail: "",
      primaryPhone: "",
      secondaryPhone: "",
      domain: "",
      address: "",
      statusID: "",
    },
    defaultItem: {
      payrollDate: "",
      name: "",
      interestPercentage: "",
      primaryEmail: "",
      secondaryEmail: "",
      primaryPhone: "",
      secondaryPhone: "",
      domain: "",
      address: "",
      statusID: "",
    },
  }),
  computed: {
    formTitle() {
      return this.editedIndex === -1
        ? "Create Organization"
        : "Edit Organization";
    },
    companyNameErrors() {
      const errors = [];
      if (!this.$v.editedItem.name.$dirty) return errors;
      !this.$v.editedItem.name.required &&
        errors.push("Company name is required.");
      return errors;
    },
    companyDomainErrors() {
      const errors = [];
      if (!this.$v.editedItem.domain.$dirty) return errors;
      !this.$v.editedItem.domain.required &&
        errors.push("Company domain is required.");
      return errors;
    },
    primaryPhonenumberErrors() {
      const errors = [];
      if (!this.$v.editedItem.primaryPhone.$dirty) return errors;
      !this.$v.editedItem.primaryPhone.maxLength &&
        errors.push("Phone number must be at most 13 characters long");
      !this.$v.editedItem.primaryPhone.minLength &&
        errors.push("Phone number must be more than 10 characters long");
      !this.$v.editedItem.primaryPhone.numeric &&
        errors.push("Phone number must only contain number characters(+ve integers)");
      !this.$v.editedItem.primaryPhone.required &&
        errors.push("Primary phone number is required.");
      return errors;
    },
    primaryEmailErrors() {
      const errors = [];
      if (!this.$v.editedItem.primaryEmail.$dirty) return errors;
      !this.$v.editedItem.primaryEmail.required &&
        errors.push("Primary email address is required.");
      !this.$v.editedItem.primaryEmail.email &&
        errors.push("Please enter a valid email address");
      return errors;
    },
    payrollDateErrors() {
      const errors = [];
      if (!this.$v.editedItem.payrollDate.$dirty) return errors;
      !this.$v.editedItem.payrollDate.required &&
        errors.push("Payroll date is required.");
      !this.$v.editedItem.payrollDate.integer &&
        errors.push("Please enter a valid payroll date");
      !this.$v.editedItem.payrollDate.between &&
        errors.push("Payroll date must be between 1-31");
      return errors;
    },
    addressErrors() {
      const errors = [];
      if (!this.$v.editedItem.address.$dirty) return errors;
      !this.$v.editedItem.address.required &&
        errors.push("Company address is required.");
      return errors;
    },
    ...mapState(["organization"]),
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  created() {
    this.initialize();
  },

  methods: {
    async initialize() {
      this.showLoader();
      await this.$store.dispatch("organization/fetchOrganizations"),
        (this.items = store.state.organization.organizations.data);
      this.responsePayload = store.state.organization.organizations;
      this.hideLoader();
    },

    editItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.items.splice(this.editedIndex, 1);
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    async save() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.submitStatus = "ERROR";
      } else {
        if (this.editedIndex > -1) {
          Object.assign(this.items[this.editedIndex], this.editedItem);
        } else {
          this.items.push(this.editedItem);
        }
        this.close();
      }
    },
  },
  mounted() {
    document.title = "Organizations | Kredwise";
  },
};
</script>
<style lang="scss" scoped></style>
